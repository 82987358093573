import React from "react";
import DataTable from "../../../shared/components/DataTable";
import { FlexContainer } from "../../../shared/components/styled/Container.styled";
import PaymentActivityCell from "../../../shared/components/table/PaymentActivityCell";

const DashboardPaymentActivity = ({
  headers = [],
  rows = [],
  emptyDataMessage = "",
  rowAttributes = [],
}) => {
  return (
    <FlexContainer width="70%" alignself="flex-start" padding="0 0 0 50px">
      <DataTable
        headers={headers}
        rows={rows}
        emptyDataMessage={emptyDataMessage}
        rowAttributes={rowAttributes}
        CellDisplayComponent={PaymentActivityCell}
        minWidth="350px"
      />
    </FlexContainer>
  );
};

export default DashboardPaymentActivity;
