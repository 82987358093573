import React, { useContext, useEffect } from "react";
import RequireAuth from "../../shared/components/guards/RequireAuth";
import { PageTitle } from "../../shared/components/styled/Typography.styled";
import { Box, Paper, Tooltip } from "@mui/material";
import {
  AsideNavigation,
  FlexContainer,
  RelativeContainer,
} from "../../shared/components/styled/Container.styled";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Statements from "./statements/Statements";
import {
  BANNER_IMAGE_STATEMENTS_KEY,
  BANNER_URL_STATEMENTS_KEY,
  DOCUMENTS_ALLOW_ACCESS_KEY,
  DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY,
  DOCUMENTS_ALLOW_UPLOAD_KEY,
} from "../../shared/constants/Configurator.constants";
import MarketingBanner from "../../shared/components/configurator/MarketingBanner";
import LoanDetailsExpandable from "../../shared/components/LoanDetailsExpandable";
import { ConfiguratorContext } from "../../contexts/ConfiguratorContext";
import {
  REQUEST_PAYOFF_NOT_ALLOWED_MESSAGE,
  UPLOAD_NOT_ALLOWED,
  VIEW_DOCUMENT_NOT_ALLOWED_MESSAGE,
} from "../../shared/constants/StatementsDocuments.constants";
import { findInArray, isAllowed } from "../../shared/utils/utility-functions";

const StatementsAndDocuments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { edittedFields } = useContext(ConfiguratorContext);

  const configuratorDocumentsAllowField = findInArray(
    edittedFields,
    "key",
    DOCUMENTS_ALLOW_ACCESS_KEY,
  );
  const configuratorDocumentsUploadAllowField = findInArray(
    edittedFields,
    "key",
    DOCUMENTS_ALLOW_UPLOAD_KEY,
  );
  const configuratorPayoffQuoteAllowField = findInArray(
    edittedFields,
    "key",
    DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY,
  );

  const canViewDocuments = isAllowed(
    configuratorDocumentsAllowField,
    "value",
    "true",
    true,
  );
  const canUploadDocuments = isAllowed(
    configuratorDocumentsUploadAllowField,
    "value",
    "true",
    true,
  );
  const canPayoffQuote = isAllowed(
    configuratorPayoffQuoteAllowField,
    "value",
    "true",
    true,
  );

  useEffect(() => {
    if (
      !canViewDocuments &&
      (location.pathname === "/statements-documents" ||
        location.pathname === "/statements-documents/")
    ) {
      if (canUploadDocuments) {
        navigate("/statements-documents/upload", { replace: true });
      } else if (canPayoffQuote) {
        navigate("/statements-documents/request", { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canViewDocuments, canUploadDocuments, canPayoffQuote, location]);

  return (
    <RequireAuth>
      <RelativeContainer width="100%">
        <PageTitle
          variant="h1"
          component="h1"
          color="primary.dark"
          fontSize="3rem"
        >
          Statements & Documents
        </PageTitle>
        <LoanDetailsExpandable />
      </RelativeContainer>
      <Paper elevation={0}>
        <FlexContainer padding="25px 100px" alignitems="flex-start">
          <AsideNavigation>
            <Tooltip
              title={!canViewDocuments ? VIEW_DOCUMENT_NOT_ALLOWED_MESSAGE : ""}
            >
              <Box
                sx={{
                  cursor: !canViewDocuments ? "not-allowed" : "inherit",
                  lineHeight: 1.286,
                }}
              >
                <Link
                  to="/statements-documents"
                  className={
                    !canViewDocuments
                      ? "disabled"
                      : location.pathname === "/statements-documents" ||
                        location.pathname === "/statements-documents/"
                      ? "active"
                      : ""
                  }
                >
                  Statements
                </Link>
              </Box>
            </Tooltip>
            <Tooltip
              title={!canViewDocuments ? VIEW_DOCUMENT_NOT_ALLOWED_MESSAGE : ""}
            >
              <Box
                sx={{
                  cursor: !canViewDocuments ? "not-allowed" : "inherit",
                  lineHeight: 1.286,
                }}
              >
                <Link
                  to="tax"
                  className={
                    !canViewDocuments
                      ? "disabled"
                      : location.pathname.includes("/statements-documents/tax")
                      ? "active"
                      : ""
                  }
                >
                  Tax documents
                </Link>
              </Box>
            </Tooltip>
            <Tooltip
              title={!canViewDocuments ? VIEW_DOCUMENT_NOT_ALLOWED_MESSAGE : ""}
            >
              <Box
                sx={{
                  cursor: !canViewDocuments ? "not-allowed" : "inherit",
                  lineHeight: 1.286,
                }}
              >
                <Link
                  to="other"
                  className={
                    !canViewDocuments
                      ? "disabled"
                      : location.pathname.includes(
                          "/statements-documents/other",
                        )
                      ? "active"
                      : ""
                  }
                >
                  Other Documents
                </Link>
              </Box>
            </Tooltip>
            <Tooltip
              title={!canPayoffQuote ? REQUEST_PAYOFF_NOT_ALLOWED_MESSAGE : ""}
            >
              <Box
                sx={{
                  cursor: !canPayoffQuote ? "not-allowed" : "inherit",
                  lineHeight: 1.286,
                }}
              >
                <Link
                  to="request"
                  className={
                    !canPayoffQuote
                      ? "disabled"
                      : location.pathname.includes(
                          "/statements-documents/request",
                        )
                      ? "active"
                      : ""
                  }
                >
                  Request Payoff Quote
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title={!canUploadDocuments ? UPLOAD_NOT_ALLOWED : ""}>
              <Box
                sx={{
                  cursor: !canUploadDocuments ? "not-allowed" : "inherit",
                  lineHeight: 1.286,
                }}
              >
                <Link
                  to="upload"
                  className={
                    !canUploadDocuments
                      ? "disabled"
                      : location.pathname.includes(
                          "/statements-documents/upload",
                        )
                      ? "active"
                      : ""
                  }
                >
                  Upload Documents
                </Link>
              </Box>
            </Tooltip>
          </AsideNavigation>
          <FlexContainer padding="25px" flexgrow="2">
            <FlexContainer width="100%" flexdirection="column" gap="25px">
              {location.pathname === "/statements-documents" ||
              location.pathname === "/statements-documents/" ? (
                <Statements />
              ) : (
                <Outlet />
              )}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer padding="25px 100px" alignitems="flex-start">
          <MarketingBanner
            elementKey={BANNER_IMAGE_STATEMENTS_KEY}
            hyperlinkElementKey={BANNER_URL_STATEMENTS_KEY}
          />
        </FlexContainer>
      </Paper>
    </RequireAuth>
  );
};

export default StatementsAndDocuments;
