import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useTheme } from "@mui/styles";
import { Paper } from "@mui/material";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import HistoryEduTwoToneIcon from "@mui/icons-material/HistoryEduTwoTone";
import TaxInsuranceDetails from "./components/TaxInsuranceDetails";
import DashboardPaymentActivity from "./components/DashboardPaymentActivity";
import DashboardPaymentDetails from "./components/DashboardPaymentDetails";
import DashboardSummaryCard from "./components/DashboardSummaryCard";
import { ThemeContext } from "../../contexts/ThemeContext";
import { ConfiguratorContext } from "../../contexts/ConfiguratorContext";
import { AuthContext } from "../../contexts/AuthContext";
import { getPaymentActivity } from "../../data/paymentsAPI";
import { PageTitle } from "../../shared/components/styled/Typography.styled";
import {
  DashboardCardMedium,
  DashboardCardSmall,
  FlexContainer,
} from "../../shared/components/styled/Container.styled";
import ConfirmationDialog from "../../shared/components/ConfirmationDialog";
import ActionSnackbar from "../../shared/components/ActionSnackbar";
import DescriptionSection from "../../shared/components/dashboard/DescriptionSection";
import {
  ERROR,
  NOT_APPLICABLE,
  PLACEHOLDER_DESCRIPTION,
  SUCCESS,
} from "../../shared/constants/Defaults.constants";
import {
  PAYMENT_ACTIVITY_FIELD_TYPES,
  PAYMENT_ACTIVITY_HEADER_NAMES,
  PAYMENT_ACTIVITY_NO_DATA_MESSAGE,
} from "../../shared/constants/Payments.constants";
import RequireAuth from "../../shared/components/guards/RequireAuth";
import {
  findElementIncludedInArray,
  hex2rgba,
  padCents,
  toDateStringNoDay,
} from "../../shared/utils/utility-functions";
import {
  BANNER_IMAGE_DASHBOARD_KEY,
  BANNER_URL_DASHBOARD_KEY,
  DASHBOARD_PAYMENT_ACTIVITY_CAPTION_KEY,
  DASHBOARD_TAX_AND_INSURANCE_CAPTION_KEY,
  ESTATEMENT_SIGNUP_TERMS_AND_CONDITIONS_KEY,
  HAS_ADVERT_KEY,
} from "../../shared/constants/Configurator.constants";
import MarketingBanner from "../../shared/components/configurator/MarketingBanner";
import { format } from "date-fns";
import {
  NO_LOAN_REGISTERED_WARNING_MESSAGE,
  PAPER_STATEMENT_UPDATE_PREFERENCES_FAILED,
  PAPER_STATEMENT_UPDATE_PREFERENCES_SUCCESS,
} from "../../shared/constants/UserProfile.constants";
import { EventType } from "@azure/msal-browser";
import LoginAdvertPopup from "./components/LoginAdvertPopup";
import { utcToZonedTime } from "date-fns-tz";
import {
  CLIENT_ADMIN_ROLE,
  PAYMENTS_BORROWER_ROLE,
  PAYMENTS_SUPPORT_ROLE,
} from "../../shared/constants/Roles.constants";
import { getLoanData, updatePrefereneces } from "../../data/loanAPI";
import EditableTermsAndConditions from "../../shared/components/configurator/EditableTermsAndConditions";
import LoanDetailsExpandable from "../../shared/components/LoanDetailsExpandable";
// import LoanDetails from "./components/LoanDetails";
// import LoanBalance from "./components/LoanBalance";

const Dashboard = () => {
  const { instance } = useMsal();
  const { addPendingRequest, removePendingRequest } = useContext(ThemeContext);
  const { edittedFields } = useContext(ConfiguratorContext);
  const {
    token,
    userDetails,
    userRoles,
    activeLoan,
    activeLoanDetails,
    setActiveLoanDetails,
    impersonatedUser,
    impersonatedUserDetails,
  } = useContext(AuthContext);
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const theme = useTheme();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Payment Details Card States
  const [dueDate, setDueDate] = useState(new Date().toLocaleDateString());
  const [autoPaymentDate, setAutoPaymentDate] = useState(
    new Date().toLocaleDateString(),
  );
  const [lastPaymentReceived, setLastPaymentDate] = useState(
    new Date().toLocaleDateString(),
  );
  const [amountDue, setAmountDue] = useState("0");
  const [principalAndInterest, setPrincipalAndInterest] = useState("0");
  const [escrow, setEscrow] = useState("0");
  const [autopay, setAutopay] = useState(false);

  // Summary Card States
  const [loanAmount, setLoanAmount] = useState("0");
  const [newStatementsAndDocuments, setNewStatementsAndDocuments] = useState(0);
  const [estatementDialogOpen, setEstatementDialogOpen] = useState(false);

  // Tax And Insurance States
  const [realEstateCurrent, setRealEstateCurrent] = useState(NOT_APPLICABLE);
  const [hazardCurrent, setHazardCurrent] = useState(NOT_APPLICABLE);

  // Payment Activity States
  const [paymentActivity, setPaymentActivity] = useState([]);

  // Configurator States
  const [taxAndInsuranceDescription, setTaxAndInsuranceDescription] = useState(
    PLACEHOLDER_DESCRIPTION,
  );
  const [paymentActivityDescription, setPaymentActivityDescription] = useState(
    PLACEHOLDER_DESCRIPTION,
  );

  // Snackbar States
  // Loan
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // E-Statement
  const [severity, setSeverity] = useState("error");
  const [snackbarEStatementOpen, setSnackbarEStatementOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [paperless, setPaperless] = useState(false);

  //Loan Pop-up
  const [loanOpen, setLoanOpen] = useState(false);

  //Login Advert
  const [openLoginAdvert, setOpenLoginAdvert] = useState(false);
  const [hasLoginAdvert, setHasLoginAdvert] = useState(false);

  //Terms and Conditions E-Statement
  const [termsAndConditionsText, setTermsAndConditionsText] = useState("");
  const [termsAndConditionsCheckbox, setTermsAndConditionsCheckbox] =
    useState(false);

  useEffect(() => {
    if (userRoles?.includes(PAYMENTS_SUPPORT_ROLE) && !impersonatedUser) {
      navigate("/support", { replace: true });
    }
    const callbackId = instance.addEventCallback((message) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        setOpenLoginAdvert(true);
      }
    });

    return () => {
      if (callbackId) {
        // Remove Event callback when unmounting
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated && userDetails && token) {
      callDashboardApi(token);
    }
    setPaperless(activeLoanDetails?.loanDetails?.paperless);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userDetails,
    instance,
    isAuthenticated,
    token,
    activeLoan,
    activeLoanDetails,
  ]);

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
  }, [edittedFields]);

  const callDashboardApi = async (token) => {
    addPendingRequest();

    if (activeLoan) {
      if (activeLoan !== "no-loan") {
        if (activeLoan === activeLoanDetails?.loanDetails?.loanId) {
          const sumFormatter = new Intl.NumberFormat("en-US");

          setDashboardDataStates(activeLoanDetails, sumFormatter);

          const paymentActivityData = await getPaymentActivity(
            token,
            impersonatedUser,
            activeLoan,
            1,
            4,
          );
          setPaymentActivityDataStates(paymentActivityData, sumFormatter);
        }
      } else {
        setSnackbarOpen(true);
      }
    }
    removePendingRequest();
  };

  const setDashboardDataStates = (data, sumFormatter) => {
    setDueDate(data.loanDetails.dueDate.replaceAll("-", "/"));
    setAutoPaymentDate(
      data.loanDetails.autoPaymentDate
        ? format(
            utcToZonedTime(data.loanDetails.autoPaymentDate, userTimeZone),
            "MM/dd/yyyy",
          )
        : "",
    );
    setLastPaymentDate(
      data.loanDetails.lastPaymentReceived.replaceAll("-", "/"),
    );
    setAmountDue(padCents(sumFormatter.format(+data.loanDetails.amountDue)));
    setPrincipalAndInterest(
      padCents(sumFormatter.format(+data.loanDetails.amountPrincipalInterest)),
    );
    setEscrow(
      data?.loanDetails?.currentEscrowPayment
        ? padCents(sumFormatter.format(+data.loanDetails.currentEscrowPayment))
        : NOT_APPLICABLE,
    );
    setAutopay(data.loanDetails.autoPayActive);
    setLoanAmount(
      padCents(sumFormatter.format(+data.loanDetails.mortgageBalance)),
    );
    setNewStatementsAndDocuments(
      data.documents && data.statements ? data.statements + data.documents : 0,
    );
    setRealEstateCurrent(
      data.realEstateTax
        ? padCents(sumFormatter.format(+data.realEstateTax))
        : NOT_APPLICABLE,
    );
    const currentHazard = data.insuranceDetails.find(
      (item) => item?.type.toString().toLowerCase() === "hazard",
    );
    setHazardCurrent(
      currentHazard
        ? padCents(sumFormatter.format(+currentHazard.amount))
        : NOT_APPLICABLE,
    );
  };

  const setPaymentActivityDataStates = (data, sumFormatter) => {
    if (!data.message) {
      const tableData = data.response.map((item) => {
        const itemDate = utcToZonedTime(item.date, userTimeZone);
        const formattedDate = `${`0${itemDate.getMonth() + 1}`.slice(
          -2,
        )}/${`0${itemDate.getDate()}`.slice(-2)}/${itemDate.getFullYear()}`;
        return {
          type: item.paymentType,
          date: item.date ? formattedDate : "Unknown Date",
          status: item.status.toUpperCase(),
          amount: `$ ${padCents(sumFormatter.format(item.amount))}`,
          disabled: item.status.toUpperCase() === "PENDING",
        };
      });
      setPaymentActivity(tableData.slice(0, 4));
    }
  };

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case DASHBOARD_TAX_AND_INSURANCE_CAPTION_KEY:
          setTaxAndInsuranceDescription(item.value);
          return;
        case DASHBOARD_PAYMENT_ACTIVITY_CAPTION_KEY:
          setPaymentActivityDescription(item.value);
          return;
        case HAS_ADVERT_KEY:
          setHasLoginAdvert(item.value === "true");
          return;
        case ESTATEMENT_SIGNUP_TERMS_AND_CONDITIONS_KEY:
          setTermsAndConditionsText(item.value);
          return;
        default:
          return;
      }
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const confirmOptinEStatement = async () => {
    setEstatementDialogOpen(false);
    let status;
    if (termsAndConditionsCheckbox) {
      addPendingRequest();
      if (activeLoan) {
        if (activeLoan !== "no-loan") {
          status = await updatePrefereneces(
            token,
            activeLoan,
            impersonatedUser,
            true,
          );
          const updatedLoanDetails = await getLoanData(
            token,
            activeLoan,
            impersonatedUser,
          );
          setActiveLoanDetails(updatedLoanDetails);
          setPaperless(updatedLoanDetails?.loanDetails?.paperless);
        }
      }
      removePendingRequest();
      displayStatusAPICall(status);
    }
  };

  const displayStatusAPICall = (status) => {
    if (status && status.success) {
      setSnackbarMessage(PAPER_STATEMENT_UPDATE_PREFERENCES_SUCCESS);
      setSeverity(SUCCESS);
    } else {
      setSnackbarMessage(PAPER_STATEMENT_UPDATE_PREFERENCES_FAILED);
      setSeverity(ERROR);
    }
    setSnackbarEStatementOpen(true);
  };

  return (
    <RequireAuth>
      <PageTitle
        variant="h1"
        component="h1"
        color="primary.dark"
        fontSize="3rem"
      >
        Welcome,{" "}
        {impersonatedUserDetails
          ? impersonatedUserDetails?.displayName
          : userDetails?.name}
        !
      </PageTitle>
      <FlexContainer
        padding="0 100px 25px 100px"
        gap="25px"
        alignitems="stretch"
      >
        <FlexContainer width="50%">
          <DashboardCardMedium>
            <DashboardPaymentDetails
              dueDate={toDateStringNoDay(dueDate)}
              autopay={autopay}
              amountDue={amountDue}
              principalInterestAmount={principalAndInterest}
              escrowAmount={escrow}
              lastPaymentReceived={toDateStringNoDay(lastPaymentReceived)}
              autoPaymentDate={autoPaymentDate}
            />
          </DashboardCardMedium>
        </FlexContainer>
        <FlexContainer flexdirection="column" gap="25px" width="25%">
          <DashboardCardSmall>
            <DashboardSummaryCard
              Icon={ManageAccountsTwoToneIcon}
              iconColor="primary"
              iconSize="64px"
              label="Manage Your Account"
              breakpoint=" "
              data=" "
              valueFontSize="1.2857rem"
              clickAction={(e) => navigate("/profile")}
              cursor="pointer"
            />
          </DashboardCardSmall>
          <DashboardCardSmall>
            <DashboardSummaryCard
              Icon={ArticleTwoToneIcon}
              iconColor="primary"
              iconSize="64px"
              label="Statements & Documents"
              breakpoint="&nbsp;"
              data={`${newStatementsAndDocuments}${String.fromCharCode(
                160,
              )}New`}
              valueFontSize="1.2857rem"
              clickAction={(e) => navigate("/statements-documents")}
              cursor="pointer"
            />
          </DashboardCardSmall>
        </FlexContainer>
        <FlexContainer flexdirection="column" gap="25px" width="25%">
          <DashboardCardSmall>
            <DashboardSummaryCard
              Icon={MonetizationOnTwoToneIcon}
              iconColor="primary"
              iconSize="64px"
              label="Loan"
              breakpoint="."
              data={loanAmount}
              prefix="$"
              valueFontSize="1.2857rem"
              prefixFontSize="0.8571rem"
              clickAction={() => setLoanOpen(true)}
              cursor="pointer"
            />
          </DashboardCardSmall>
          <DashboardCardSmall>
            <DashboardSummaryCard
              background={
                activeLoan && activeLoan === "no-loan"
                  ? "rgba(0, 0, 0, 0.1)"
                  : paperless && !userRoles?.includes(CLIENT_ADMIN_ROLE)
                  ? hex2rgba(theme.palette.primary.main, 0.1)
                  : "transparent"
              }
              Icon={HistoryEduTwoToneIcon}
              iconColor="primary"
              iconSize="64px"
              label="E-Statement Signup"
              breakpoint=" "
              data=" "
              valueFontSize="1.2857rem"
              clickAction={
                (paperless && !userRoles?.includes(CLIENT_ADMIN_ROLE)) ||
                (activeLoan && activeLoan === "no-loan")
                  ? (event) => event.preventDefault()
                  : () => setEstatementDialogOpen(true)
              }
              cursor={
                (paperless && !userRoles?.includes(CLIENT_ADMIN_ROLE)) ||
                (activeLoan && activeLoan === "no-loan")
                  ? "default"
                  : "pointer"
              }
            />
          </DashboardCardSmall>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer padding="0 100px 25px 100px">
        <MarketingBanner
          elementKey={BANNER_IMAGE_DASHBOARD_KEY}
          hyperlinkElementKey={BANNER_URL_DASHBOARD_KEY}
        />
      </FlexContainer>
      <Paper elevation={0}>
        <FlexContainer
          flexdirection="column"
          alignitems="flex-start"
          padding="25px 100px"
          gap="25px"
        >
          {/* <FlexContainer gap="25px" width="100%" justifycontent="flex-start">
            <LoanDetails />
            <LoanBalance />
          </FlexContainer> */}
          {activeLoanDetails?.loanDetails?.fullEscrowLoan ? (
            <FlexContainer gap="25px" width="100%">
              <DescriptionSection
                title="Tax & Insurance"
                body={taxAndInsuranceDescription}
                setBody={setTaxAndInsuranceDescription}
                bodyKey={DASHBOARD_TAX_AND_INSURANCE_CAPTION_KEY}
                wasModified={findElementIncludedInArray(
                  edittedFields,
                  "modified",
                  true,
                  "key",
                  DASHBOARD_TAX_AND_INSURANCE_CAPTION_KEY,
                )}
                hasAction
                actionLabel="Find Insurance Plans"
                actionCallback={navigate}
                actionCallbackArguments={["/house"]}
                width="30%"
              />
              <TaxInsuranceDetails
                realEstateCurrent={realEstateCurrent}
                hazardCurrent={hazardCurrent}
              />
            </FlexContainer>
          ) : (
            ""
          )}
          <FlexContainer gap="25px" width="100%">
            <DescriptionSection
              title="Payment Activity"
              body={paymentActivityDescription}
              setBody={setPaymentActivityDescription}
              bodyKey={DASHBOARD_PAYMENT_ACTIVITY_CAPTION_KEY}
              wasModified={findElementIncludedInArray(
                edittedFields,
                "modified",
                true,
                "key",
                DASHBOARD_PAYMENT_ACTIVITY_CAPTION_KEY,
              )}
              hasAction
              actionLabel="View Transaction History"
              actionCallback={navigate}
              actionCallbackArguments={["/payments/activity"]}
              width="30%"
            />
            <DashboardPaymentActivity
              headers={PAYMENT_ACTIVITY_HEADER_NAMES}
              rows={paymentActivity}
              rowAttributes={PAYMENT_ACTIVITY_FIELD_TYPES}
              emptyDataMessage={PAYMENT_ACTIVITY_NO_DATA_MESSAGE}
            />
          </FlexContainer>
        </FlexContainer>
      </Paper>
      {/*Pop-up dialog for E-Statement*/}
      <ConfirmationDialog
        open={estatementDialogOpen}
        dismiss={() => setEstatementDialogOpen(false)}
        closeButtonDisabled={!termsAndConditionsCheckbox}
        close={confirmOptinEStatement}
        title="Do you want to opt-in for e-statements?"
        body={
          <EditableTermsAndConditions
            width={"80%"}
            renderedText={termsAndConditionsText}
            setRenderedText={setTermsAndConditionsText}
            checkboxActive={termsAndConditionsCheckbox}
            setCheckboxActive={setTermsAndConditionsCheckbox}
            elementKey={ESTATEMENT_SIGNUP_TERMS_AND_CONDITIONS_KEY}
            wasModified={findElementIncludedInArray(
              edittedFields,
              "modified",
              true,
              "key",
              ESTATEMENT_SIGNUP_TERMS_AND_CONDITIONS_KEY,
            )}
            padding="25px 0"
            fontSize={12}
          />
        }
        hasActions={
          !!(!impersonatedUser && userRoles?.includes(PAYMENTS_BORROWER_ROLE))
        }
        confirmButtonLabel="Accept"
        dismissButtonLabel="Cancel"
      />
      {/*Loan pop-up dialog */}
      <ConfirmationDialog
        open={loanOpen}
        hasActions={false}
        hasTitle={false}
        hasComponent
        close={() => setLoanOpen(false)}
        dismiss={() => setLoanOpen(false)}
        body={<LoanDetailsExpandable position={"relative"} expanded popup />}
      />
      {/*Loan Message Snackbar*/}
      <ActionSnackbar
        severity="warning"
        borderColor={theme.palette.warning.main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarOpen}
        closeFn={handleSnackbarClose}
        snackbarLabel={NO_LOAN_REGISTERED_WARNING_MESSAGE}
        actionLabel="Register Loan"
        actionFn={() => navigate("/profile/loan")}
      />
      {/*E-Statement Snackbar*/}
      <ActionSnackbar
        severity={severity}
        borderColor={theme.palette[severity].main}
        vertical="top"
        horizontal="center"
        width="50%"
        isOpen={snackbarEStatementOpen}
        closeFn={() => {
          setSnackbarEStatementOpen(false);
        }}
        snackbarLabel={snackbarMessage}
        hasAction={false}
        autoHideDuration={6000}
      />
      {hasLoginAdvert ? (
        <LoginAdvertPopup open={openLoginAdvert} setOpen={setOpenLoginAdvert} />
      ) : (
        ""
      )}
    </RequireAuth>
  );
};

export default Dashboard;
