import React, { useContext, useEffect, useState } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DashboardInfoItem from "./dashboard/DashboardInfoItem";
import { CustomContainer, FlexContainer } from "./styled/Container.styled";
import { Box, Tooltip, Typography } from "@mui/material";
import { PillButton } from "./styled/Button.styled";
import { AuthContext } from "../../contexts/AuthContext";
import { format } from "date-fns";
import { NOT_APPLICABLE } from "../constants/Defaults.constants";
import { useNavigate } from "react-router-dom";
import { DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY } from "../constants/Configurator.constants";
import { ConfiguratorContext } from "../../contexts/ConfiguratorContext";
import { findInArray, isAllowed } from "../utils/utility-functions";
import { REQUEST_PAYOFF_NOT_ALLOWED_MESSAGE } from "../constants/StatementsDocuments.constants";
import { utcToZonedTime } from "date-fns-tz";

function LoanDetailsExpandable({
  position = "absolute",
  expanded = false,
  popup = false,
}) {
  const navigate = useNavigate();
  const { activeLoanDetails } = useContext(AuthContext);
  const { edittedFields } = useContext(ConfiguratorContext);

  const [isExpanded, setIsExpanded] = useState(expanded);
  const [userAddress, setUserAddress] = useState(NOT_APPLICABLE);
  const [isSpecialLoan, setIsSpecialLoan] = useState(false);

  const sumFormatter = new Intl.NumberFormat("en-US");
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const configuratorPayoffQuoteAllowField = findInArray(
    edittedFields,
    "key",
    DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY,
  );

  const canPayoffQuote = isAllowed(
    configuratorPayoffQuoteAllowField,
    "value",
    "true",
    true,
  );

  useEffect(() => {
    if (activeLoanDetails) {
      const addr = [
        activeLoanDetails?.borrowerDetails?.address?.trim(),
        activeLoanDetails?.borrowerDetails?.city?.trim(),
        activeLoanDetails?.borrowerDetails?.state?.trim(),
        activeLoanDetails?.borrowerDetails?.postalCode?.trim(),
      ];

      if (
        activeLoanDetails?.loanDetails?.loanType === "A" ||
        activeLoanDetails?.loanDetails?.amortizationType === "H" ||
        activeLoanDetails?.loanDetails?.amortizationType === "C"
      ) {
        setIsSpecialLoan(true);
      } else {
        setIsSpecialLoan(false);
      }

      const filteredAddress = addr.filter((item) => !!item);
      setUserAddress(filteredAddress.join(", "));
    }
  }, [activeLoanDetails]);

  return activeLoanDetails ? (
    <CustomContainer
      position={position}
      sx={{
        top: "25%",
        right: position === "absolute" ? 100 : 0,
        zIndex: 5,
        transition: "all 1s ease-in-out",
      }}
    >
      <FlexContainer
        background={popup ? "transparent" : "#ffe8ba"}
        padding="10px 15px"
        flexdirection="column"
        alignitems="flex-start"
        justifycontent="flex-start"
        sx={{
          maxWidth: 768,
          height: isExpanded
            ? isSpecialLoan
              ? "234.13px"
              : "173.13px"
            : "56px",
          width: isExpanded ? "768px" : "514px",
          transition: "all 0.3s ease-in-out",
          overflow: "hidden",
        }}
      >
        <FlexContainer gap="25px" flexwrap="wrap" justifycontent="flex-start">
          <FlexContainer gap="5px">
            <HomeOutlinedIcon color="primary" fontSize="large" />
            {isExpanded ? (
              <Typography color="primary" fontWeight="bold">
                Loan Details
              </Typography>
            ) : (
              ""
            )}
          </FlexContainer>
          <FlexContainer
            gap="25px"
            sx={{
              width: isExpanded ? "100%" : "auto",
              transition: "all 0.3s ease-in-out",
            }}
            justifycontent="flex-start"
          >
            <DashboardInfoItem
              label="Current Balance"
              labelFontSize={10}
              data={
                activeLoanDetails?.loanDetails?.mortgageBalance
                  ? `$ ${sumFormatter.format(
                      activeLoanDetails?.loanDetails?.mortgageBalance,
                    )}`
                  : NOT_APPLICABLE
              }
              valueFontSize={14}
              textOverflow
              withTooltip
              maxWidth="90px"
            />
            <DashboardInfoItem
              label="Monthly Payment"
              labelFontSize={10}
              data={
                activeLoanDetails?.loanDetails?.amountDue
                  ? `$ ${sumFormatter.format(
                      activeLoanDetails?.loanDetails?.amountDue,
                    )}`
                  : NOT_APPLICABLE
              }
              valueFontSize={14}
              textOverflow
              withTooltip
              maxWidth="90px"
            />
            <DashboardInfoItem
              label="Interest Rate"
              labelFontSize={10}
              data={
                activeLoanDetails?.loanDetails?.interestRate
                  ? `% ${sumFormatter.format(
                      activeLoanDetails?.loanDetails?.interestRate,
                    )}`
                  : NOT_APPLICABLE
              }
              valueFontSize={14}
              textOverflow
              withTooltip
              maxWidth="90px"
            />
            <DashboardInfoItem
              label="Escrow Balance"
              labelFontSize={10}
              data={
                activeLoanDetails?.loanDetails?.escrowBalance
                  ? `$ ${sumFormatter.format(
                      activeLoanDetails?.loanDetails?.escrowBalance,
                    )}`
                  : NOT_APPLICABLE
              }
              valueFontSize={14}
              textOverflow
              withTooltip
              maxWidth="90px"
            />
            {isExpanded ? (
              <>
                <DashboardInfoItem
                  label="Original Loan Balance"
                  labelFontSize={10}
                  data={
                    activeLoanDetails?.loanDetails?.originalBalance
                      ? `$ ${sumFormatter.format(
                          activeLoanDetails?.loanDetails?.originalBalance,
                        )}`
                      : NOT_APPLICABLE
                  }
                  valueFontSize={14}
                  textOverflow
                  withTooltip
                  maxWidth="90px"
                />
                <Tooltip
                  title={
                    !canPayoffQuote ? REQUEST_PAYOFF_NOT_ALLOWED_MESSAGE : ""
                  }
                >
                  <Box
                    sx={{
                      cursor: !canPayoffQuote ? "not-allowed" : "inherit",
                      lineHeight: 1.286,
                    }}
                  >
                    <PillButton
                      variant="outlined"
                      width="200px"
                      onClick={(e) =>
                        navigate("/statements-documents/request", {
                          replace: true,
                        })
                      }
                      disabled={!canPayoffQuote}
                      sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        display: "block",
                      }}
                    >
                      Request Payoff Quote
                    </PillButton>
                  </Box>
                </Tooltip>
              </>
            ) : (
              ""
            )}
          </FlexContainer>
        </FlexContainer>
        <FlexContainer
          gap="25px"
          sx={{
            height: isExpanded ? "auto" : 0,
            paddingTop: isExpanded ? "25px" : 0,
            overflow: "hidden",
            transition: "all 0.3 ease-in-out",
          }}
        >
          <DashboardInfoItem
            label="Property Address"
            labelFontSize={10}
            data={userAddress || NOT_APPLICABLE}
            valueFontSize={14}
            textOverflow
            withTooltip
            maxWidth="393px"
          />
          <DashboardInfoItem
            label="Interest Paid Last Year"
            labelFontSize={10}
            data={
              activeLoanDetails?.loanDetails?.amountInterestLastYear
                ? `$ ${sumFormatter.format(
                    activeLoanDetails?.loanDetails?.amountInterestLastYear,
                  )}`
                : NOT_APPLICABLE
            }
            valueFontSize={14}
            textOverflow
            withTooltip
            maxWidth="125px"
          />
          <DashboardInfoItem
            label="Maturity Date"
            labelFontSize={10}
            data={
              activeLoanDetails?.loanDetails?.maturityDate
                ? format(
                    utcToZonedTime(
                      activeLoanDetails?.loanDetails?.maturityDate,
                      userTimeZone,
                    ),
                    "MMM d, yyyy",
                  )
                : NOT_APPLICABLE
            }
            valueFontSize={14}
            textOverflow
            withTooltip
            maxWidth="125px"
          />
          <DashboardInfoItem
            label="Closing Date"
            labelFontSize={10}
            data={
              activeLoanDetails?.loanDetails?.closingDate
                ? format(
                    utcToZonedTime(
                      activeLoanDetails?.loanDetails?.closingDate,
                      userTimeZone,
                    ),
                    "MMM d, yyyy",
                  )
                : NOT_APPLICABLE
            }
            valueFontSize={14}
            textOverflow
            withTooltip
            maxWidth="125px"
          />
        </FlexContainer>
        {isSpecialLoan ? (
          <FlexContainer
            gap="25px"
            sx={{
              height: isExpanded ? "auto" : 0,
              paddingTop: isExpanded ? "25px" : 0,
              overflow: "hidden",
              transition: "all 0.3 ease-in-out",
            }}
          >
            {activeLoanDetails?.loanDetails?.loanType === "A" ? (
              <>
                <DashboardInfoItem
                  label="Index"
                  labelFontSize={10}
                  data={
                    activeLoanDetails?.loanDetails?.armIndex || NOT_APPLICABLE
                  }
                  valueFontSize={14}
                  textOverflow
                  withTooltip
                  maxWidth="125px"
                />
                <DashboardInfoItem
                  label="Rate Next Change Date"
                  labelFontSize={10}
                  data={
                    activeLoanDetails?.loanDetails?.armRateNextChangeDate
                      ? format(
                          utcToZonedTime(
                            activeLoanDetails?.loanDetails
                              ?.armRateNextChangeDate,
                            userTimeZone,
                          ),
                          "MMM d, yyyy",
                        )
                      : NOT_APPLICABLE
                  }
                  valueFontSize={14}
                  textOverflow
                  withTooltip
                  maxWidth="125px"
                />
                <DashboardInfoItem
                  label="Payment Next Change Date"
                  labelFontSize={10}
                  data={
                    activeLoanDetails?.loanDetails?.armPaymentNextChangeDate
                      ? format(
                          utcToZonedTime(
                            activeLoanDetails?.loanDetails
                              ?.armPaymentNextChangeDate,
                            userTimeZone,
                          ),
                          "MMM d, yyyy",
                        )
                      : NOT_APPLICABLE
                  }
                  valueFontSize={14}
                  textOverflow
                  withTooltip
                  maxWidth="125px"
                />
              </>
            ) : (
              ""
            )}
            {activeLoanDetails?.loanDetails?.amortizationType === "H" ? (
              <>
                <DashboardInfoItem
                  label="Line of Credit"
                  labelFontSize={10}
                  data={
                    activeLoanDetails?.loanDetails?.hcAvailable
                      ? `$ ${sumFormatter.format(
                          activeLoanDetails?.loanDetails?.hcAvailable,
                        )}`
                      : NOT_APPLICABLE
                  }
                  valueFontSize={14}
                  textOverflow
                  withTooltip
                  maxWidth="125px"
                />
                <DashboardInfoItem
                  label="Available Credit"
                  labelFontSize={10}
                  data={
                    activeLoanDetails?.loanDetails?.hcRemaining
                      ? `$ ${sumFormatter.format(
                          activeLoanDetails?.loanDetails?.hcRemaining,
                        )}`
                      : NOT_APPLICABLE
                  }
                  valueFontSize={14}
                  textOverflow
                  withTooltip
                  maxWidth="125px"
                />
              </>
            ) : activeLoanDetails?.loanDetails?.amortizationType === "C" ? (
              <DashboardInfoItem
                label="Construction Balance"
                labelFontSize={10}
                data={
                  activeLoanDetails?.loanDetails?.hcAvailable
                    ? `$ ${sumFormatter.format(
                        activeLoanDetails?.loanDetails?.hcAvailable,
                      )}`
                    : NOT_APPLICABLE
                }
                valueFontSize={14}
                textOverflow
                withTooltip
                maxWidth="125px"
              />
            ) : (
              ""
            )}
          </FlexContainer>
        ) : (
          ""
        )}
      </FlexContainer>
      {!expanded && (
        <Typography
          fontSize={10}
          fontWeight="bold"
          color="primary"
          sx={{
            position: "absolute",
            top: 30,
            right: 15,
            cursor: "pointer",
            userSelect: "none",
          }}
          onClick={(e) =>
            setIsExpanded((previousIsExpanded) => !previousIsExpanded)
          }
        >
          {isExpanded ? "See Less" : "View More"}
        </Typography>
      )}
    </CustomContainer>
  ) : (
    ""
  );
}

export default LoanDetailsExpandable;
