import React, { useContext } from "react";
import {
  ClickAwayListener,
  Divider,
  Fade,
  MenuItem,
  Paper,
  Popper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutIcon from "@mui/icons-material/Logout";
import { FlexContainer } from "./styled/Container.styled";
import { AuthContext } from "../../contexts/AuthContext";
import { getHours } from "date-fns";
import {
  DEFAULT_AFTERNOON_GREETING,
  DEFAULT_EVENING_GREETING,
  DEFAULT_MORNING_GREETING,
  DEFAULT_USER_NAME,
} from "../constants/Defaults.constants";
import {
  CLIENT_ADMIN_ROLE,
  PAYMENTS_BORROWER_ROLE,
  PAYMENTS_SUPPORT_ROLE,
} from "../constants/Roles.constants";
import { hex2rgba } from "../utils/utility-functions";

const AccountPopper = ({
  accountPopperOpen = false,
  anchorElement = null,
  handleAccountPopperClose = () => {},
  accountCallback = () => {},
  settingsCallback = () => {},
  impersonateCallback = () => {},
  endImpersonationSessionCallback = () => {},
  logoutCallback = () => {},
}) => {
  const theme = useTheme();
  const { userDetails, userRoles, impersonatedUser, impersonatedUserDetails } =
    useContext(AuthContext);
  const currentHours = getHours(new Date());

  const determineGreeting = (hour) => {
    if (hour >= 5 && hour < 12) {
      return DEFAULT_MORNING_GREETING;
    }
    if (hour >= 12 && hour < 18) {
      return DEFAULT_AFTERNOON_GREETING;
    }
    if (hour >= 18 || hour < 5) {
      return DEFAULT_EVENING_GREETING;
    }
  };

  const determineUserName = () => {
    if (impersonatedUser) {
      return impersonatedUserDetails?.givenName &&
        impersonatedUserDetails?.surname
        ? `${impersonatedUserDetails.givenName} ${impersonatedUserDetails.surname}`
        : DEFAULT_USER_NAME;
    }
    return userDetails?.idTokenClaims?.given_name &&
      userDetails?.idTokenClaims?.family_name
      ? `${userDetails?.idTokenClaims?.given_name} ${userDetails?.idTokenClaims?.family_name}`
      : DEFAULT_USER_NAME;
  };

  return (
    <Popper
      open={accountPopperOpen}
      anchorEl={anchorElement}
      placement="bottom-start"
      transition
      sx={{ zIndex: 10 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper sx={{ padding: 2, paddingBottom: 1 }}>
            <ClickAwayListener onClickAway={handleAccountPopperClose}>
              <Stack>
                <FlexContainer
                  flexdirection="column"
                  alignitems="flex-start"
                  padding="0 0 10px 0"
                >
                  <Typography fontWeight="bold">
                    {determineGreeting(currentHours)},
                  </Typography>
                  <Typography fontWeight="bold" color="primary">
                    {determineUserName()}
                  </Typography>
                </FlexContainer>
                <Divider sx={{ marginBottom: "8px" }} />
                {userRoles?.includes(PAYMENTS_BORROWER_ROLE) ||
                userRoles?.includes(CLIENT_ADMIN_ROLE) ||
                impersonatedUser ? (
                  <>
                    <MenuItem
                      sx={{ paddingLeft: "3px", paddingRight: "3px" }}
                      onClick={accountCallback}
                    >
                      <FaceOutlinedIcon
                        fontSize="small"
                        sx={{ marginRight: 2 }}
                      />
                      <Typography>Account</Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{ paddingLeft: "3px", paddingRight: "3px" }}
                      onClick={settingsCallback}
                    >
                      <SettingsOutlinedIcon
                        fontSize="small"
                        sx={{ marginRight: 2 }}
                      />
                      <Typography>Settings</Typography>
                    </MenuItem>
                    <Divider />
                  </>
                ) : null}
                {userRoles?.includes(CLIENT_ADMIN_ROLE) ||
                userRoles?.includes(PAYMENTS_SUPPORT_ROLE) ? (
                  <>
                    {impersonatedUser ? (
                      <MenuItem
                        sx={{
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          background: hex2rgba(theme.palette.primary.main, 0.1),
                          borderRadius: "5px",

                          "&:hover": {
                            background: hex2rgba(
                              theme.palette.primary.main,
                              0.2,
                            ),
                          },
                        }}
                        onClick={endImpersonationSessionCallback}
                      >
                        <LogoutIcon fontSize="small" sx={{ marginRight: 2 }} />
                        <Typography>End Session</Typography>
                      </MenuItem>
                    ) : (
                      <MenuItem
                        sx={{ paddingLeft: "3px", paddingRight: "3px" }}
                        onClick={impersonateCallback}
                      >
                        <SupportAgentIcon
                          fontSize="small"
                          sx={{ marginRight: 2 }}
                        />
                        <Typography>Impersonate</Typography>
                      </MenuItem>
                    )}

                    <Divider />
                  </>
                ) : null}
                <MenuItem
                  sx={{ paddingLeft: "3px", paddingRight: "3px" }}
                  disabled={!!impersonatedUser}
                  onClick={logoutCallback}
                >
                  <PowerSettingsNewIcon
                    fontSize="small"
                    sx={{ marginRight: 2 }}
                  />
                  <Typography>Logout</Typography>
                </MenuItem>
              </Stack>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default AccountPopper;
