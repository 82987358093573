import { Divider, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../../../../contexts/ConfiguratorContext";
import { FlexContainer } from "../../../../../shared/components/styled/Container.styled";
import { SectionTitle } from "../../../../../shared/components/styled/Typography.styled";
import {
  DOCUMENTS_ALLOWED_TO_BE_DOWNLOADED_KEY,
  DOCUMENTS_ALLOWED_TO_BE_UPLOADED_KEY,
  DOCUMENTS_ALLOW_ACCESS_KEY,
  DOCUMENTS_ALLOW_ACCESS_LABEL,
  DOCUMENTS_ALLOW_ACCESS_SECONDARY_LABEL,
  DOCUMENTS_ALLOW_PAPER_STATEMENTS_KEY,
  DOCUMENTS_ALLOW_PAPER_STATEMENTS_LABEL,
  DOCUMENTS_ALLOW_PAPER_STATEMENTS_SECONDARY_LABEL,
  DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY,
  DOCUMENTS_ALLOW_REQUEST_PAYOFF_LABEL,
  DOCUMENTS_ALLOW_REQUEST_PAYOFF_SECONDARY_LABEL,
  DOCUMENTS_ALLOW_UPLOAD_KEY,
  DOCUMENTS_ALLOW_UPLOAD_LABEL,
  DOCUMENTS_ALLOW_UPLOAD_SECONDARY_LABEL,
  NOTIFICATIONS_ALLOW_KEY,
  NOTIFICATIONS_ALLOW_LABEL,
  NOTIFICATIONS_ALLOW_SECONDARY_LABEL,
  NOTIFICATIONS_CONFIRMATION_EMAIL_KEY,
  NOTIFICATIONS_CONFIRMATION_EMAIL_LABEL,
  NOTIFICATIONS_APPARENT_SENDER_KEY,
  NOTIFICATIONS_APPARENT_SENDER_LABEL,
  NOTIFICATIONS_PAYMENT_CANCELLED_LABEL,
  NOTIFICATIONS_PAYMENT_CANCELLED_KEY,
  NOTIFICATIONS_RECURRING_PAYMENT_KEY,
  NOTIFICATIONS_RECURRING_PAYMENT_LABEL,
  NOTIFICATIONS_STATEMENT_DELIVERY_CHANGE_KEY,
  NOTIFICATIONS_STATEMENT_DELIVERY_CHANGE_LABEL,
} from "../../../../../shared/constants/Configurator.constants";
import {
  DOCUMENT_DOWNLOAD_TYPES,
  DOCUMENT_UPLOAD_TYPES,
} from "../../../../../shared/constants/StatementsDocuments.constants";
import { hex2rgba, validateEmail } from "../../../../../shared/utils/utility-functions";
import ExpandableSetting from "../../../shared/ExpandableSetting";
import MultipleSelectionSetting from "../../../shared/MultipleSelectionSetting";
import CompanyDetailsForm from "./components/CompanyDetailsForm.js";
import NotificationPreferences from "./components/NotificationPreferences";
import PaperStatementAddressForm from "./components/PaperStatementAddressForm";
import PayoffRequestPreferences from "./components/PayoffRequestPreferences";
import RadioSetting from "../../../shared/RadioSetting";
import InputSetting from "../../../shared/InputSetting";

const UserAccountRequirements = () => {
  const theme = useTheme();
  const { edittedFields, addNewEdittedField } = useContext(ConfiguratorContext);

  const [allowConfirmationEmail, setAllowConfirmationEmail] = useState(true);
  const [senderEmail, setSenderEmail] = useState("");
  const [errorSenderEmail, setErrorSenderEmail] = useState("");
  const [allowEmailPaymentCancelled, setAllowEmailPaymentCancelled] = useState(true);
  const [allowEmailRecurringPayment, setAllowEmailRecurringPayment] = useState(true);
  const [statementDeliveryChange, setStatementDeliveryChange] = useState(true);

  const allowedDocumentDownloadFromConfig = edittedFields.find(
    (item) => item.key === DOCUMENTS_ALLOWED_TO_BE_DOWNLOADED_KEY,
  );
  const allowedDocumentUploadFromConfig = edittedFields.find(
    (item) => item.key === DOCUMENTS_ALLOWED_TO_BE_UPLOADED_KEY,
  );

  const [allowedDocumentDownloadList, setAllowedDocumentDownloadList] =
    useState(
      allowedDocumentDownloadFromConfig
        ? allowedDocumentDownloadFromConfig?.value?.length > 0
          ? allowedDocumentDownloadFromConfig.value.split(";")
          : []
        : [...DOCUMENT_DOWNLOAD_TYPES],
    );
  const [allowedDocumentUploadList, setAllowedDocumentUploadList] = useState(
    allowedDocumentUploadFromConfig
      ? allowedDocumentUploadFromConfig?.value?.length > 0
        ? allowedDocumentUploadFromConfig.value.split(";")
        : []
      : [...DOCUMENT_UPLOAD_TYPES],
  );

  useEffect(() => {
    fillConfiguratorFields(edittedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fillConfiguratorFields = (fields) => {
    fields.forEach((item) => {
      switch (item.key) {
        case NOTIFICATIONS_CONFIRMATION_EMAIL_KEY:
          setAllowConfirmationEmail(item.value === "true");
          return;
        case NOTIFICATIONS_APPARENT_SENDER_KEY:
          setSenderEmail(item.value);
          return;
        case NOTIFICATIONS_PAYMENT_CANCELLED_KEY:
          setAllowEmailPaymentCancelled(item.value === "true");
          return;
        case NOTIFICATIONS_RECURRING_PAYMENT_KEY:
          setAllowEmailRecurringPayment(item.value === "true");
          return;
        case NOTIFICATIONS_STATEMENT_DELIVERY_CHANGE_KEY:
          setStatementDeliveryChange(item.value === "true");
          return;
        default:
          return;
      }
    });
  };

  const onChangeHandler = (e) => {
    setSenderEmail(e.target.value);
    const isValid = validateEmail(e.target.value);
    if (!isValid && e.target.value !== ""){
      setErrorSenderEmail("Email not valid");
    } else {
      setErrorSenderEmail("");
      addNewEdittedField({
        key: NOTIFICATIONS_APPARENT_SENDER_KEY,
        value: e.target.value,
        modified: true,
      });
    }
  };

  return (
    <>
      <SectionTitle variant="h2" color="primary">
        User Account Requirements
      </SectionTitle>
      <FlexContainer
        flexdirection="column"
        width="100%"
        maxwidth="1720px"
        alignitems="center"
        padding="0 0 25px 0"
        gap="25px"
      >
        <Divider flexItem />
        <ExpandableSetting
          elementKey={DOCUMENTS_ALLOW_PAPER_STATEMENTS_KEY}
          defaultValue="true"
          mainLabel={DOCUMENTS_ALLOW_PAPER_STATEMENTS_LABEL}
          expandCondition="false"
          secondaryLabel={DOCUMENTS_ALLOW_PAPER_STATEMENTS_SECONDARY_LABEL}
          expandAnimation
          expandHeight="280px"
          expandedComponent={<PaperStatementAddressForm />}
        />
        <Divider flexItem />
        <ExpandableSetting
          elementKey={DOCUMENTS_ALLOW_ACCESS_KEY}
          mainLabel={DOCUMENTS_ALLOW_ACCESS_LABEL}
          expandCondition="true"
          secondaryLabel={DOCUMENTS_ALLOW_ACCESS_SECONDARY_LABEL}
          expandAnimation
          expandHeight="480px"
          expandedComponent={
            <MultipleSelectionSetting
              elementKey={DOCUMENTS_ALLOWED_TO_BE_DOWNLOADED_KEY}
              list={DOCUMENT_DOWNLOAD_TYPES}
              selection={allowedDocumentDownloadList}
              setSelection={setAllowedDocumentDownloadList}
              background={hex2rgba(theme.palette.primary.main, 0.1)}
              sx={{ maxHeight: "calc(100% - 41px)" }}
            />
          }
        />
        <Divider flexItem />
        <ExpandableSetting
          elementKey={DOCUMENTS_ALLOW_UPLOAD_KEY}
          mainLabel={DOCUMENTS_ALLOW_UPLOAD_LABEL}
          expandCondition="true"
          secondaryLabel={DOCUMENTS_ALLOW_UPLOAD_SECONDARY_LABEL}
          expandAnimation
          expandHeight="235px"
          expandedComponent={
            <MultipleSelectionSetting
              elementKey={DOCUMENTS_ALLOWED_TO_BE_UPLOADED_KEY}
              list={DOCUMENT_UPLOAD_TYPES}
              selection={allowedDocumentUploadList}
              setSelection={setAllowedDocumentUploadList}
              background={hex2rgba(theme.palette.primary.main, 0.1)}
              sx={{ maxHeight: "calc(100% - 41px)" }}
            />
          }
        />
        <Divider flexItem />
        <ExpandableSetting
          elementKey={NOTIFICATIONS_ALLOW_KEY}
          mainLabel={NOTIFICATIONS_ALLOW_LABEL}
          expandCondition="true"
          secondaryLabel={NOTIFICATIONS_ALLOW_SECONDARY_LABEL}
          expandAnimation
          expandHeight="345px"
          expandedComponent={<NotificationPreferences />}
        />
        <Divider flexItem />
        <CompanyDetailsForm />
        <Divider flexItem />
        <ExpandableSetting
          elementKey={DOCUMENTS_ALLOW_REQUEST_PAYOFF_KEY}
          mainLabel={DOCUMENTS_ALLOW_REQUEST_PAYOFF_LABEL}
          expandCondition="true"
          secondaryLabel={DOCUMENTS_ALLOW_REQUEST_PAYOFF_SECONDARY_LABEL}
          expandAnimation
          expandHeight="345px"
          expandedComponent={<PayoffRequestPreferences />}
        />
        <Divider flexItem />
        <RadioSetting
          elementKey={NOTIFICATIONS_CONFIRMATION_EMAIL_KEY}
          label={NOTIFICATIONS_CONFIRMATION_EMAIL_LABEL}
          settingId="confirmation-email"
          settingValue={allowConfirmationEmail}
          setValue={(e) => setAllowConfirmationEmail(e.target.value)}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
        <Divider flexItem />
        <InputSetting
          elementKey={NOTIFICATIONS_APPARENT_SENDER_KEY}
          label={NOTIFICATIONS_APPARENT_SENDER_LABEL}
          inputValue={senderEmail}
          setInputValue={setSenderEmail}
          onChangeHandler={onChangeHandler}
          validateInputValue={validateEmail}
          inputPlaceholder="response@lookupmyloan.com"
          blurOnWheel
          inputProps={{
            type: "text",
            style: {
              textAlign: "end",
            },
          }}
          errorMessage={errorSenderEmail}
          setErrorMessage={setErrorSenderEmail}
        />
        <Divider flexItem />
        <RadioSetting
          elementKey={NOTIFICATIONS_PAYMENT_CANCELLED_KEY}
          label={NOTIFICATIONS_PAYMENT_CANCELLED_LABEL}
          settingId="payment-cancelled"
          settingValue={allowEmailPaymentCancelled}
          setValue={(e) => setAllowEmailPaymentCancelled(e.target.value)}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
        <Divider flexItem />
        <RadioSetting
          elementKey={NOTIFICATIONS_RECURRING_PAYMENT_KEY}
          label={NOTIFICATIONS_RECURRING_PAYMENT_LABEL}
          settingId="recurring-payment"
          settingValue={allowEmailRecurringPayment}
          setValue={(e) => setAllowEmailRecurringPayment(e.target.value)}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
        <Divider flexItem />
        <RadioSetting
          elementKey={NOTIFICATIONS_STATEMENT_DELIVERY_CHANGE_KEY}
          label={NOTIFICATIONS_STATEMENT_DELIVERY_CHANGE_LABEL}
          settingId="recurring-payment"
          settingValue={statementDeliveryChange}
          setValue={(e) => setStatementDeliveryChange(e.target.value)}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
      </FlexContainer>
    </>
  );
};

export default UserAccountRequirements;
